//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-842:_8752,_4328,_4912,_656,_6952,_64,_8192,_248;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-842')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-842', "_8752,_4328,_4912,_656,_6952,_64,_8192,_248");
        }
      }catch (ex) {
        console.error(ex);
      }